<template>
	<div>
		<div class="cation">
			<header class="addheaders">
				<span class="back" @click="headback">
					<i class="el-icon-arrow-left" style="font-size:14px;"></i>
					返回
					<i class="line">|</i>
				</span>
				<span class="titile">{{textid == 0 ? "创建商品参数" : "编辑商品参数"}}</span>
			</header>
			<nav class="argumentnav">
				<div class="params_wrap">
					<div class="params_head">
						<p>商品参数设置</p>
					</div>
					<div class="params_content">
						<el-form :rules="paramRules" ref="paramForm" :model="paramForm" label-width="160px">
							<el-form-item label="商品分类" prop="value">
								<div class="params_title">
									<el-cascader placeholder="请选择分类" v-model="paramForm.value" :options="paramForm.options" filterable clearable
										:props="{ value: 'id',label: 'label',children: 'children'}"
										@change="handleChange"></el-cascader>
								</div>
							</el-form-item>
							<el-form-item label="商品参数名称" prop="cat_attr_name">
								<div class="params_title">
									<el-input placeholder="请输入商品参数名称" maxlength="20" v-model="paramForm.cat_attr_name"
										show-word-limit>
									</el-input>
								</div>
							</el-form-item>
							<div class="params_tble">
								<el-table :data="tableData" style="width: 100%">
									<el-table-column width="100">
										<template slot-scope="scope">
											<div class="params_move">
												<i></i>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="100" label="类型">
										<template slot-scope="scope">
											<div>
												<template v-if="scope.row.type=='1'">单行文本</template>
												<template v-if="scope.row.type=='2'">单选框</template>
												<template v-if="scope.row.type=='3'">多选框</template>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="参数名称" width="300">
										<template slot-scope="scope">
											<div v-if="scope.row.fillType!=3" class="params_input">
												<el-input placeholder="请输入参数名称" v-model="scope.row.attr_name">
												</el-input>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="必填" width="50">
										<template slot-scope="scope">
											<div>
												<el-checkbox :checked="scope.row.required == 1 ? true : false" @change="must($event,scope)"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="参数类型" width="400">
										<template slot-scope="scope">
											<div v-if="scope.row.type=='1'" class="d-flex align-items-center flex-wrap">
												<div class="attribute_type">
													<el-input placeholder="请输入默认值" v-model="scope.row.default">
													</el-input>
												</div>
												<div class="attribute_type">
													<el-input placeholder="提示语" v-model="scope.row.tips">
													</el-input>
												</div>
											</div>
											<div class="dhtext" v-if="scope.row.type=='2'">
												<el-input v-model="scope.row.default" placeholder="请输入选项值,选项值请用英文逗号,分割">
												</el-input>
												<!-- <p>选项值请用英文逗号,分割</p> -->
											</div>
											<div v-if="scope.row.type=='3'">
												<el-input v-model="scope.row.default" placeholder="请输入选项值,项值请用英文逗号,分割">
												</el-input>
												<!-- <p>选项值请用英文逗号,分割</p> -->
											</div>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="150">
										<template slot-scope="scope">
											<div class="caozuo" @click="delparams(scope)">删除</div>
										</template>
									</el-table-column>

									<template slot="empty">
										<div class="empty_data">
											<p>暂无数据</p>
										</div>
									</template>
								</el-table>
								<div class="argument_foot">
									<div class="argument_foot_select">
										<el-select v-model="attribute">
											<el-option v-for="(item,index) in natureList" :key="item.value"
												:label="item.label" :value="item.value"></el-option>
										</el-select>
									</div>
									<el-button type="primary" @click="addparams">+添加新参数</el-button>
								</div>
							</div>

						</el-form>
					</div>
				</div>
				<div class="params_container">
					<div class="ivu-card-head">
						<p>商品参数预览</p>
					</div>
					<div class="params_card_body">
						<div class="params_card_content">
							<div class="goodsParam bor-b">
								<div class="bor-t bor-l d-flex params_card_wrap align-items-center" v-for="(item,index) in tableData"
									:key="index">
									<div>{{item.fillType!=3?item.attr_name:''}}</div>
									<div class="bor-r bor-l flex_1">{{item.default}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<diy-footer-button>
		    <el-button type="primary" @click="argumentsubmit('paramForm')" v-if="hasPerm(['goods.label.store','goods.label.update'])">保存</el-button>
		    <el-button @click="headback()">取消</el-button>
		</diy-footer-button>
	</div>
</template>

<script>
	import Sortable from "sortablejs";
	export default {
		data() {
			return {
				textid: this.$route.params.id,
				paramRules: {
					value: {
						required: true,
						message: "请选择商品分类",
						trigger: "change",
					},
					cat_attr_name: {
						required: true,
						message: '请输入商品参数名称',
						trigger: 'blur'
					}
				},
				paramForm: {
					value: '',
					options: [],
					cat_attr_name: '', //参数名称
				},
				tableData: [],
				natureList: [{
						label: '单行文本',
						value: '1',
					},
					{
						label: '单选',
						value: '2',
					},
					{
						label: '多选',
						value: '3'
					}
				],
				attribute: '', //商品选中的参数
			}
		},
		components: {
			Sortable
		},
		mounted: function () {
		    this.initSort();
			this.argumentCatlist();
			if(this.textid > 0){
				this.loopdelect()
			}
		},
		methods: {
			
			argumentCatlist() {
				this.$get(this.$apis.catList).then(res => {
					if (res.code == 200) {
						this.paramForm.options = this.getTreeData(res.data.list)
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].children.length < 1) {
						data[i].children = undefined;
					} else {
						this.getTreeData(data[i].children);
					}
				}
				return data;
			},
			// 查看商品参数详情
			loopdelect(){
				this.$get(this.$apis.loopPara + this.textid).then(res => {
					if (res.code == 200) {
						this.paramForm.cat_attr_name = res.data.cat_attr_name;
						this.paramForm.value = res.data.cats;
						// for (let i = 0; i < res.data.param_detail.length; i++) {
						// 	if(res.data.param_detail[i].default){
						// 		res.data.param_detail[i].default = JSON.parse(res.data.param_detail[i].default);
						// 		res.data.param_detail[i].default = res.data.param_detail[i].default.toString();
						// 	}
						// }
						this.tableData = res.data.param_detail;
						this.tableData.forEach(item => {
							item.default = item.default.toString()
						})
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
			},
			
			headback() {
				window.history.go(-1)
			},
			handleChange(value) {
				// console.log(value);
			},
			addparams() {
				if(this.attribute == ''){
					this.$message({
						showClose: true,
						message: '请选择参数类型',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false
				}
				let obj = {
					type: this.attribute,
					name: '',
					default: '', //参数值
					required: 0,
				};
				this.tableData.push(obj);
			},
			delparams(scope) {
				let index = scope.$index;
				this.tableData.splice(index, 1)
			},
			must(val,scope){
				if(this.tableData[scope.$index].required == 1){
				    this.tableData[scope.$index].required = 0
				}else{
				    this.tableData[scope.$index].required = 1
				}
			},
			initSort() {
				const el = document.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
				// const sortable = new Sortable(el, options);
				// 根据具体需求配置options配置项
				var that = this;
				const sortable = new Sortable(el, {
					onEnd: (evt) => { // 监听拖动结束事件
						// console.log(this) // this是当前vue上下文
						console.log(evt.oldIndex) // 当前行的被拖拽前的顺序
						console.log(evt.newIndex) // 当前行的被拖拽后的顺序
						// console.log(this.tableData)
						// 这里就可以写我们需要传给后台的逻辑代码
						// 我们有了 evt.oldIndex 和 evt.newIndex 这两个参数做索引，我们可以根据绑定在表格上面的 data 这个 Array 找到两个相应的记录。就可以针对数据进行操作啦。
						// 下面将拖拽后的顺序进行修改
						// this.$nextTick(function(){
						//     const currRow = that.tableData.splice(evt.oldIndex, 1)[0];
						//     that.tableData.splice(evt.newIndex, 0, currRow)
						//     const newData = [];
						//     console.log(currRow)
						//     that.tableData.forEach((item, index) => {
						//         newData[index] = {
						//             id: item.id,
						//             rank: index + 1,
						//             title: item.title,
						//             fillType: item.fillType,
						//             value: item.value, //参数值
						//         }
						//     })
						// })

						// 下面是将排序结果更新到数据库中，根据自己项目的逻辑进行实现即可。

					}
				})
			},
			// 保存
			argumentsubmit(paramForm){
				if(!this.tableData.length){
					this.$message({
						showClose: true,
						message: '请选择参数并填写',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false
				}
				let detaillist = this.tableData;
				for (var i = 0; i < detaillist.length; i++) {
					if(detaillist[i].default){
						if( typeof detaillist[i].default == 'string'){
							detaillist[i].default = detaillist[i].default.split(",");
						}
					}
					if(detaillist[i].attr_name == undefined){
						this.$message({
							showClose: true,
							message: '请填写参数信息',
							type: 'warning',
							offset: 200,
							duration: 1000
						});
						return false
					}
				}
				let data = {
					cat_id: this.paramForm.value[this.paramForm.value.length-1],
					cats: this.paramForm.value,
					cat_attr_name: this.paramForm.cat_attr_name,
					detail: detaillist
				};
				// console.log(data);
				// return false;
				if(this.textid == 0){
					this.$refs[paramForm].validate((valid) => {
						if (valid) {
							this.$post(this.$apis.addParam,data).then(res => {
								if(res.code == 200){
									console.log(res)
									this.$message({
										type: 'success',
										duration: 1500,
										message: '保存成功',
										onClose :()=>{
											this.$router.replace({
												path:"/goods/goods_arguments",
											});
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}else{
					this.$refs[paramForm].validate((valid) => {
						if (valid) {
							this.$put(this.$apis.paramEdit + this.textid,data).then(res => {
								if(res.code == 200){
									// console.log(res)
									this.$message({
										type: 'success',
										duration: 1500,
										message: '修改成功',
										onClose :()=>{
											this.$router.replace({
												path:"/goods/goods_arguments",
											});
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}
			},
		},
	};
</script>

<style scoped>
	/deep/.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
		background: #535353;
	}
	
	/deep/.el-table--scrollable-x .el-table__body-wrapper {
		background: transparent;
	}
	.addheaders{margin-bottom: 20px;}
	.cation {
		padding-bottom: 58px;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.argumentnav {
		flex: 1;
		margin-top: 0;
		display: flex;
		background: transparent;
		flex-direction: initial;
	}

	.params_wrap {
		margin: 24px;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
		overflow-x: hidden;
		background-color: #fff;
		flex: 1;
	}

	.params_head {
		border-bottom: 1px solid #f2f2f2;
		padding: 8px 20px;
		line-height: 1;
	}

	.params_head p {
		font-size: 16px;
		display: inline-block;
		width: auto;
		line-height: 28px;
		color: #333;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 700;
	}

	.params_content {
		padding: 20px;
		padding-bottom: 0;
	}

	.params_tble /deep/.el-table>.el-table__body-wrapper>.el-table__body {
		width: 100% !important;
	}

	.params_tble /deep/.el-table>.el-table__header-wrapper>.el-table__header {
		width: 100% !important;
	}
	.params_title /deep/ .el-cascader .el-input{width: 300px;}
	.params_title .el-input {
		width: 300px;
	}

	.params_title .el-input__inner {
		width: 300px;
	}

	.params_move {
		text-align: center;
	}

	.params_move i {
		font-size: 20px;
		display: inline-block;
		width: 16px;
		height: 16px;
		background: url(../../assets/images/cgview.svg) center no-repeat;
		vertical-align: -3px;
		margin-right: 2px;
	}



	.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.el-tabs__item {
		padding: 16px;
		padding-left: 16px;
		height: auto;
		line-height: normal;
		color: #666;
	}

	.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav>.is-active {
		font-weight: 600;
		color: var(--fontColor);
	}

	.params_tble .el-tabs--top .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav-scroll .el-tabs__nav .el-tabs__active-bar {
		background-color: var(--fontColor);
		/* min-width: 60px; */
	}

	.params_input .el-input {
		width: 200px;
	}

	.caozuo {
		color: var(--fontColor);
		cursor: pointer;
	}

	.argument_foot {
		padding: 16px 0px 16px 72px;
	}

	.empty_data {
		width: 100%;
		padding: 10px 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

	}

	.empty_data p {
		margin-top: 16px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.4);
		line-height: 20px;
	}

	.params_container {
		width: 310px;
		margin-left: 0px;
		background-color: #fff;
	}
	.dhtext /deep/ .el-input{width: 100%;}

	.ivu-card-head {
		border-bottom: 1px solid #f2f2f2;
		padding: 8px 20px;
		line-height: 1;
	}

	.ivu-card-head p {
		font-size: 16px;
		display: inline-block;
		width: auto;
		line-height: 28px;
		color: #333;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 700;
	}

	.params_card_body {
		padding: 20px;
		padding-bottom: 0;
	}

	.params_card_content {
		background: #fff;
		zoom: .7;
		height: 640px;
		overflow: auto;
	}

	.goodsParam {
		background: #fff;
		font-size: 12px;
		color: #666;
	}

	.bor-b {
		border-bottom: 1px solid #ededed;
	}

	.bor-t {
		border-top: 1px solid #ededed;
	}

	.bor-l {
		border-left: 1px solid #ededed;
		min-height: 44px;
	}

	.bor-r {
		border-right: 1px solid #ededed;
	}

	.params_card_wrap>div:first-of-type {
		padding: 9px 14px;
		width: 170px;
		align-items: center;
		display: block;
		word-wrap: break-word;
		box-sizing: border-box;
	}

	.params_card_wrap>div:nth-of-type(2) {
		padding: 9px 20px;
		min-width: 0;
		display: block;
		word-wrap: break-word;
		box-sizing: border-box;
	}

	.attribute_type {
		width: 49%;margin-right: 2%;
	}
	.attribute_type .el-input{width: 100%;}

	.attribute_type:nth-child(2) {
		margin-right: 0;
	}

	.argument_foot_select {
		display: inline-block;
		margin-right: 10px;
	}
	.argument_foot_select .el-select{width: 200px;}
	.argument_foot_select /deep/ .el-select .el-input{width: 100%;}

	/* 商品参数列表 */
	.parameters {
		padding: 20px;
		flex: 1;
		background: #fff;
	}

	.caozuo{
		padding: 0 10px;
		cursor: pointer;
		display: inline-block;
		line-height: 1;
		color: var(--fontColor);
	}

	.parameters_wrap table th,
	table td {
		border: none;
	}

	.parameters_wrap .el-table .el-table__header-wrapper .el-table__header thead tr th {
		background-color: #f9f9fc;
	}

	.parameters_wrap .el-table .el-table__header-wrapper {
		background-color: #f9f9fc;
	}

	.parameters_wrap .el-table .el-table__fixed .el-table__fixed-header-wrapper {
		background-color: #f9f9fc;
	}

	.el-table th.el-table__cell {
		background-color: #f9f9fc;
		font-weight: 600;
		color: #333;
		padding: 8px 0;
	}
</style>
